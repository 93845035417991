@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
* {

    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;

}

:root {

    overflow-x: hidden;

}

html {

    scroll-behavior: smooth;

}

.App {

    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;


}

.intro {

    width: 100vw;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C8A096;

}

.intro h1 {

    font-size: 28pt;
    color: #707D65;
    line-height: 35px;

}

.intro img {

    /* width: 30%; */
    height: 130%;
}

.intro span {

    font-family: 'Shadows Into Light', cursive;
    color: #F4F2F3; 
    /* definir a cor com ela */

}

.imgIntro {

    background-color: #C8A096;

}

.subtitle {

    display: flex;
    width: 100vw;
    justify-content: space-between;
    align-items: center;

}

.subtitle img {

    width: 10px;
    height: 60px;

}

.imgIntro figure {

    width: 100%;
    position: relative;
    text-align: center;

}

.imgIntro figcaption {

    /* display: flex; */
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 14pt;
    color:white;
    line-height: 20px;

}

.imgIntro figcaption img {


    width: 50px;
    height: 50px;

}

.imgIntro img {

    max-width: 100vw;
    max-height: 500px;
    border-radius: 30px;

}

.DefaultSection {

    width: 100vw;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.whiteBackgroud {

    background-color: #F4F2F3;
    /* background-image: url("../../imgs/folha.png");
    background-position: center center;
    /* background-size: 100vw ; */
    /* background-repeat: no-repeat; */

}

.greenBackgroud {

    background-color: #707D65;

}

.pinkBackgroud {

    background-color: #C8A096;

}

.blueBackgroud {

    background-color: #D1DCE2;

}

.DefaultDiv {

    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.DefaultDiv p {

    text-align: justify;
    margin: 30px 10%;

}

.phraseOne {

    background-color: #C8A096;
    color: white;

}

.infoAulas {

    width: 100vw;
}

.infoAulas figure {

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #C8A096;
    
}

.infoAulas figcaption {

    width: 80%;
    top: 25%;
    position: absolute;
    color: #707D65;
    font-size: 20pt;

}

.infoAulas img {

    width: 100%;
    
}

.subtitleInfoAulas {

    width: 80%;
    text-align: justify;
    background-color: #C8A096;

}

.boxWhatYouWillLearn {

    width: 80%;
    margin-top: 30px;
    padding: 35px;
    color: #D1DCE2;
    border-radius: 10px;
    background-color: #707D65;

}

.boxWhatYouWillLearn p {

    margin: 0;
    padding: 0;

}

.divGuests h4 {

    width: 80%;
    color: #D1DCE2;
    text-align: justify;
    font-size: 25pt;

}

.guests{

    width: 100vw;
    margin: 25px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.guests img {

    width: 350px;
    height: 350px;
    border-radius: 50%;

}

.guests p { 

    width: 80%;
    color: #707D65;
    text-align: center;
    font-size: 11pt;
    margin: 10px 0px;

}

.guests a { 

    text-decoration: none;
    width: 80%;
    color: #707D65;
    text-align: center;
    font-size: 11pt;
    margin: 10px 0px;

}

.guests h6 { 

    color: #707D65;
    width: 80%;
    text-align: center;
    text-indent: 2em;
    font-size: 11pt;
    margin: 10px 0px;

}

.guarantees {

    color: #707D65;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.guaranteesItems  {

    width: 80%;
    display: flex;
    align-items: center;
    /* justify-content: center; */

}

.guaranteesItems img{

    width: 120px;

}

.guaranteesItems legend p{

    text-align: justify;
    font-size: 10pt;
    color: #D1DCE2;
    margin: 5px 0px 5px 10px;

}

.buttonIWant {

    text-decoration: none;
    color: #707D65;
    cursor: pointer;
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #D1DCE2;
    animation: upAndDown 2s linear infinite;

}

.willLearn {

    background-color: #D1DCE2;
    color: #707D65;

}

@keyframes upAndDown {

    0% {

        transform: translateY(0px);

    }
    50% {

        transform: translateY(-8px);

    }
    100% {

        transform: translateY(0px);

    }
    
}

.formOptions {

    width: 80%;
    display: flex;
    flex-direction: column;
    color: #D1DCE2;
    text-align: center;

}

.formOptions h2{

    margin-top: 10px;
    font-size: 20pt;

}

.formOptions p{

    color: #707D65;
    margin-top: 10px;
    font-size: 18pt;
    background-color: #D1DCE2;
    border-radius: 30px;
    padding: 5px;

}

.formOptions h3{

    text-align: center;
    margin-top: 10px;
    font-size: 12pt;

}

.formOptions h4{

    text-decoration: line-through;
    margin-top: 20px;

}

.formOptions input {

    width: 60%;
    color: #707D65;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 0px 8px rgba(160, 160, 160, 0.35);
    padding: 15px;
    margin: 10px 0;
    background-color: #D1DCE2;

}

.whatsappButton img {

    width: 120px;
    height: 120px;

}

.divForm span {

    width: 80%;
    color: #D1DCE2;
    text-align: center;
    font-size: 9pt;

}

.depoimentos img{

    max-width: 100vw;

}

.depoimentos figure{

    position: relative;
    text-align: center;

}

.depoimentos figcaption{

    position: absolute;
    top: 18%;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #707D65;
    font-size: 20pt;

}

.aboutMe img{

    max-width: 100vw;

}

.aboutMe figure{

    position: relative;
    text-align: justify;

}

.aboutMe {

    color: #D1DCE2;

}

.aboutMe figcaption{

    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 45%;
    left: 45%;
    right: 0;
    font-size: 15pt;

}

.aboutMe figcaption p {

    width: 80%;

}

.aboutMe figcaption a {

    cursor: pointer;
    text-decoration: none;
    color: #D1DCE2;

}

.aboutMe figcaption img {

    width: 50px;
    border-radius: 50%;
    margin-right: 5px;

}

.textAboutMe {

    width: 80%;
    margin-top: 30px;

}

.textAboutMe p{

    font-size: 11pt;
    text-align: justify;

}

@media screen and (max-width: 768px) {


    .imgIntro img {

        width: 100vw;
        border-radius: 0px;
    
    }

    .infoAulas figcaption {

        font-size: 10pt;

    }

    .boxWhatYouWillLearn {

        padding: 15px;

    }

    .divGuests h4 {

        font-size: 12pt;
    
    }

    .guests img {

        width: 250px;
        height: 250px;

    }

    .guests h6 { 

        text-align: justify;
    
    }

    .formOptions{

        width: 80%;

    }

    .formOptions h3{

        text-align: justify;
    
    }

    .formOptions fieldset {

        margin-top: 3vh;

    }

    .formOptions input {

        width: 80%;

    }

    .formOptions fieldset legend h3 {

        font-size: 12pt;
        margin: 10px 0px;

    }
    
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

